@font-face {
    font-family: AllProSans;
    font-style: normal;
    font-weight: 300;
    src: url(https://www.nfl.com/compiledassets/assets/fonts/NFL/All-ProSans/All-ProSans--light.woff2)
        format("woff2"),
        url(https://www.nfl.com/compiledassets/assets/fonts/NFL/All-ProSans/All-ProSans--light.woff)
        format("woff");
}

@font-face {
    font-family: AllProSans;
    font-style: normal;
    font-weight: normal;
    src: url(https://www.nfl.com/compiledassets/assets/fonts/NFL/All-ProSans/All-ProSans--regular.woff2)
        format("woff2"),
        url(https://www.nfl.com/compiledassets/assets/fonts/NFL/All-ProSans/All-ProSans--regular.woff)
        format("woff");
}

@font-face {
    font-family: AllProSans;
    font-style: normal;
    font-weight: 500;
    src: url(https://www.nfl.com/compiledassets/assets/fonts/NFL/All-ProSans/All-ProSans--medium.woff2)
        format("woff2"),
        url(https://www.nfl.com/compiledassets/assets/fonts/NFL/All-ProSans/All-ProSans--medium.woff)
        format("woff");
}

@font-face {
    font-family: AllProSans;
    font-style: normal;
    font-weight: bold;
    src: url(https://www.nfl.com/compiledassets/assets/fonts/NFL/All-ProSans/All-ProSans--bold.woff2)
        format("woff2"),
        url(https://www.nfl.com/compiledassets/assets/fonts/NFL/All-ProSans/All-ProSans--bold.woff)
        format("woff");
}

#nfl-fantasy-nav {
    height: 159px;
}

.nav-container {
    box-shadow: 0 1px 2px 0 #999999;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000003;
    height: auto;
}

.nav-container a {
    text-decoration: none;
}

.left-container {
    float: left;
    margin-right: 16px;
    display: flex;
    padding-left: 16px;
}

.center-container {
    float: left;
    display: flex;
    justify-content: space-evenly;
}

.right-container {
    float: right;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    min-height: 62px;
    padding-right: 32px;
}

#nfl-fantasy-nav {
    position: relative;
}

.search-container-wrap {
    position: absolute;
    width: 100%;
    top: 100%;
}

.search-container-wrap .players-search-list {
    position: absolute;
    right: 32px;
    width: 300px;
    background: white;
    text-align: left;
    z-index: 1000004;
}
.search-container-wrap .players-search-list li:focus {
    background: red;
}
.nav-container > .content {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    background-color: #00aacc;
    z-index: 9;
    clear: left;
    text-align: left;
}

.nav-container > .content.has-fantasy-plus {
    background-color: #011a36;
}

.nav-container > .content:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.nav-container > .content {
    zoom: 1;
}

.fantasy-tools .fantasy-nav-player-search-box {
    position: absolute;
    top: 2px;
    right: 310px;
}
.fantasy-tools .fantasy-nav-player-search-box h5 {
    font: 19px / 42px NFLEndzoneSansCondMedium, Impact, Charcoal, sans-serif;
    color: #777;
    text-transform: uppercase;
}
.fantasy-tools .fantasy-nav-player-search-box h5 .icon {
    position: absolute;
    width: 35px;
    height: 43px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExODIyQUIyRTQzOUM3QjlFOSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowOTNBRUFGN0IxRjExMUUzQURGNEY4NzUwRjkxRDA4NSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowOTNBRUFGNkIxRjExMUUzQURGNEY4NzUwRjkxRDA4NSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChNYWNpbnRvc2gpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QjgwMkE0NjMxMzIwNjgxMTgyMkFBOURFN0RBMEZGNTEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDE4MDExNzQwNzIwNjgxMTgyMkFCMkU0MzlDN0I5RTkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4w7OdTAAABVklEQVR42mIsLy9noBZgwiKmC8SrgfgJEP8H4odAvBSI1UkxjBmIq4H4DBCHALE0VFwOiKOA+DxUnhmXYSxI7AlAnANlfwHifUD8AmqYExBzAnELEAsBcTE+w2yQDDoOxP5A/BpJnSIQb4d6tQiIFwPxBVzeLILSH4E4As0gELgPxNFA/AtNPVbDLKH0SiB+hCNIzkK9DgJm+AyTgNJPCUTYfTT1WA17ihQ2+IAiPkthhh2E0iG4bIUGhSuaeqyGTYHSPEixhgxAYbQCKY0tw2cYKDlMhLINoAkUZOhMID4AxMeg6Q0G2qEW40y0xdAkUQdNoB54ws4GapknNIFjZKe/QNwKxCZQb9yEioGSxHQgdoaykQ3chexCFiy2XoYmUGzADWqAMVKk7IK68CMTiaXMOywuBBm4BVcRRAh8xGLgEVzeJMXALdA0V0OJYTADbZEFAAIMALXmRsiAALNvAAAAAElFTkSuQmCC)
        no-repeat 7px 11px;
    display: inline-block;
}
.fantasy-tools .fantasy-nav-player-search-box h5 .text {
    display: inline-block;
    margin-left: 30px;
}
.fantasy-tools .fantasy-nav-player-search-box #playerSearchForm {
    color: #777;
    width: 110px;
    display: none;
    border: 1px solid #ccc;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #fff;
    padding: 5px;
    font-size: 14px;
    z-index: 1001;
    -webkit-border-radius: 4px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 4px;
    -moz-background-clip: padding;
    border-radius: 4px;
    background-clip: padding-box;
    height: 31px;
    text-align: left;
}
.fantasy-tools .fantasy-nav-player-search-box #playerSearchForm button {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExODIyQUIyRTQzOUM3QjlFOSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowOTNBRUFGN0IxRjExMUUzQURGNEY4NzUwRjkxRDA4NSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowOTNBRUFGNkIxRjExMUUzQURGNEY4NzUwRjkxRDA4NSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChNYWNpbnRvc2gpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QjgwMkE0NjMxMzIwNjgxMTgyMkFBOURFN0RBMEZGNTEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDE4MDExNzQwNzIwNjgxMTgyMkFCMkU0MzlDN0I5RTkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4w7OdTAAABVklEQVR42mIsLy9noBZgwiKmC8SrgfgJEP8H4odAvBSI1UkxjBmIq4H4DBCHALE0VFwOiKOA+DxUnhmXYSxI7AlAnANlfwHifUD8AmqYExBzAnELEAsBcTE+w2yQDDoOxP5A/BpJnSIQb4d6tQiIFwPxBVzeLILSH4E4As0gELgPxNFA/AtNPVbDLKH0SiB+hCNIzkK9DgJm+AyTgNJPCUTYfTT1WA17ihQ2+IAiPkthhh2E0iG4bIUGhSuaeqyGTYHSPEixhgxAYbQCKY0tw2cYKDlMhLINoAkUZOhMID4AxMeg6Q0G2qEW40y0xdAkUQdNoB54ws4GapknNIFjZKe/QNwKxCZQb9yEioGSxHQgdoaykQ3chexCFiy2XoYmUGzADWqAMVKk7IK68CMTiaXMOywuBBm4BVcRRAh8xGLgEVzeJMXALdA0V0OJYTADbZEFAAIMALXmRsiAALNvAAAAAElFTkSuQmCC)
        no-repeat 0px 0px;
    position: absolute;
    right: 2px;
    top: 10px;
    width: 26px;
    height: 20px;
    overflow: hidden;
    border: 0;
    padding: 0;
    font-size: 0%;
    line-height: 1px;
    color: #f0f0f0;
    cursor: pointer;
    z-index: 12;
}
.fantasy-tools .fantasy-nav-player-search-box #playerSearchQuery {
    color: #777;
    position: absolute;
    top: 10px;
    left: 2px;
    width: 80%;
    right: 30px;
    height: 20px;
    text-align: left;
    background: #fff;
    border: 0;
    padding: 0;
    border-left: 2px solid #00aacc;
    line-height: 20px;
    font-size: 13px;
    z-index: 11;
}
.fantasy-tools .fantasy-nav-player-search-box #playerSearchQuery:focus {
    outline: 0;
}
.fantasy-tools .fantasy-nav-player-search-box #playerSearchQuery::-ms-clear {
    display: none;
}
.fantasy-tools form.compact fieldset input {
    margin: 0;
}
.searchInput {
    display: flex;
}

.searchInput-hide {
    display: none;
}

.main-subnav-container {
    position: relative;
    background-color: #00aacc;
    z-index: -1;
    transition-property: all;
    transition-duration: 0.5s;
    height: 0;
    overflow: hidden;
    clear: left;
}

.main-subnav-container-slide {
    transition-property: height;
    transition-duration: 0.5s;
    height: 62px;
    z-index: -1;
}

.main-subnav-container > .content {
    float: left;
    width: 80%;
    padding-left: 90px;
    text-align: left;
}

.nav-item {
    font-family: AllProSans, sans-serif;
    font-weight: 500;
    display: inline-block;
    font-size: 14px;
    text-align: left;
    color: rgba(238, 238, 238, 0.72);
    text-transform: uppercase;
    line-height: 46px;
    padding: 8px;
}

.nav-item a {
    cursor: pointer;
    color: rgba(238, 238, 238, 0.72);
    display: block;
}

.nav-item .notification {
    background-color: #ff4400;
    border-radius: 4px;
    height: 8px;
    width: 8px;
    float: right;
    margin: 6px 0 0 -6px;
    position: relative;
}

.nav-item button,
.mobile-menu-container button {
    background: none;
    border: none;
    cursor: pointer;
    color: rgba(238, 238, 238, 0.72);
    display: block;
    font-family: AllProSans, sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 46px;
}

.nav-item button:hover,
.mobile-menu-container button:hover {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

.nav-container .center-container .nav-item.selected a,
.nav-item a:hover {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

.nfl-logo {
    width: 28.7px;
    height: 40px;
}

.fantasy-logo a {
    width: 135px;
    height: 45px;
    font-size: 24px;
    font-style: normal;
    font-stretch: normal;
    display: inline-block;
    overflow: hidden;
    margin: 10px 0 0;
}
.fantasy-logo a img {
    height: 100%;
    width: 100%;
    display: block;
}
.nav-item.more-buttons {
    position: relative;
}

.nav-item > .ellipse-more {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Crect width='4' height='4' x='2' y='10' fill='%23eee' rx='2' ry='2'/%3E%3Crect width='4' height='4' x='18' y='10' fill='%23eee' rx='2' ry='2'/%3E%3Crect width='4' height='4' x='10' y='10' fill='%23eee' rx='2' ry='2'/%3E%3C/svg%3E");
    color: rgba(255, 255, 255, 0.87);
    opacity: 1;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    font-size: 0px;
    height: 100%;
    width: 24px;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    transition-duration: 0.1s;
    justify-content: center;
    -webkit-box-pack: center;
    cursor: pointer;
    min-height: 46px;
    position: absolute;
    top: 0;
}

.nav-item > .ellipse-more-click {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    transition-duration: 0.1s;
}

.nav-item > .arrow-more {
    position: relative;
    top: -54px;
    transform: scale(0);
    transition-duration: 0.1s;
}

.arrow-more-grow {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    transition-duration: 0.1s;

    position: relative;
    top: -54px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23eee' d='m17 12.17-4 3.98V4h-2v12.2l-4-4.03-3 .05L12 20l8-7.78-3-.05z'/%3E%3C/svg%3E");
    color: rgba(255, 255, 255, 0.87);
    opacity: 1;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    height: 100%;
    width: 24px;
    justify-content: center;
    -webkit-box-pack: center;
    cursor: pointer;
    min-height: 46px;
    position: absolute;
    top: 0;
}

.nav-container-wrap .impersonating {
    position: fixed;
    top: 0px;
    left: 0px;
    background: white;
    color: #c00;
    border: 3px solid #c00;
    width: 220px;
    padding: 9px 10px;
    text-transform: none;
    font-weight: normal;
    z-index: 1000004;
    font: 20px / 25px Impact, Charcoal, sans-serif;
}
.nav-container-wrap .impersonating:hover {
    opacity: 0.5;
}

.search-icon {
    border: 0;
    max-width: 100%;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-property: opacity;
    will-change: opacity;
    opacity: 1;
    cursor: pointer;
    height: 100%;
    transition-delay: 5ms;
    width: 24px;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transition-property: opacity;
    -webkit-transition-delay: 5ms;
}

.mobile-right-container {
    display: none;
    float: right;
    padding-right: 16px;
}

.mobile-menu-button {
    all: unset;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23eee' d='M5 6h14v2H5zM5 11h14v2H5zM5 16h14v2H5z'/%3E%3C/svg%3E");
    color: rgba(255, 255, 255, 0.87);
    opacity: 1;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    font-size: 0px;
    height: 100%;
    min-height: 62px;
    width: 24px;
    transform: scale(1, 1);
    justify-content: center;
    -webkit-box-pack: center;
    cursor: pointer;
}

.mobile-menu-container {
    display: none;
}

.mobile-menu-container > .header-content {
    height: 64px;
    background-color: #00aacc;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobile-menu-container-show {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999999;
}

.mobile-menu-container > .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 46px;
    opacity: 0.95;
    background-color: #00aacc;
    height: 100%;
    position: relative;
    z-index: -1;
}

.mobile-menu-container > .header-content > .close-button {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23eee' d='m17.66 7.76-1.42-1.42L12 10.59 7.76 6.34 6.34 7.76 10.59 12l-4.25 4.24 1.42 1.42L12 13.41l4.24 4.25 1.42-1.42L13.41 12l4.25-4.24z'/%3E%3C/svg%3E");
    color: rgba(255, 255, 255, 0.87);
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    font-size: 0px;
    height: 24px;
    width: 24px;
    margin-right: 16px;

    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

.mobile-menu-container > .header-content > .close-button:focus {
    outline: 3px solid #ff8c00;
}
.mobile-menu-container > .header-content > .close-button-grow {
    transform: scale(1);
    transition-duration: 0.1s;
    cursor: pointer;
}

.mobile-menu-container > .header-content > .left-content {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 16px;
}

.profile-icon {
    transform: scale(1, 1);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23eee' d='M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm6.92 18.2a9.14 9.14 0 0 0-1.07-1.42 8.25 8.25 0 0 0-2.25-1.58 1.34 1.34 0 0 1-.37-1.12c.15-.6.6-.67 1-1.73a8.62 8.62 0 0 0 .38-3.15 4.74 4.74 0 0 0-1.73-3.45A5.08 5.08 0 0 0 12 5.92a4.78 4.78 0 0 0-2.78.83A4.74 4.74 0 0 0 7.5 10.2a7.07 7.07 0 0 0 .38 3.15c.38 1.05.83 1.13 1 1.73a1.33 1.33 0 0 1-.37 1.13 8.25 8.25 0 0 0-2.25 1.58 9.12 9.12 0 0 0-1.09 1.46 10 10 0 1 1 13.78 0Z'/%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    height: 32px;
    justify-content: center;
    width: 32px;
    -webkit-box-pack: center;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.profile-loading {
    animation: blinker 1s linear infinite;
    background-color: rgba(238, 238, 238, 0.72);
    border-radius: 3px;
    height: 14px;
    width: 50px;
    margin-right: 12px;
}

.sign-in-state-container {
    display: flex;
    align-items: center;
}

.sign-in-state {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 8px;
}

.sign-in-state a {
    float: left;
    font-family: AllProSans, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.2px;
    text-align: left;
    color: #eeeeee;
    text-transform: uppercase;
}

.sign-in-state button {
    font-family: AllProSans, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.2px;
    text-align: left;
    color: #eeeeee;
    text-transform: uppercase;
}

.mobile-profile-icon-container {
    display: flex;
    align-items: center;
    height: 100%;
    float: left;
}

.username {
    float: left;
    font-family: AllProSans, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.2px;
    text-align: left;
    color: #eeeeee;
    text-transform: uppercase;
    padding-left: 8px;
    padding-right: 8px;
}

.spacer {
    width: 1px;
    height: 16px;
    opacity: 0.3;
    background-color: #ffffff;
}

.mobile-menu-container > .header-content > .sign-in a {
    color: #fff;
}

.mobile-menu-container > .header-content > .sign-in a:hover {
    color: #fff;
}

.collapse-container .collapse {
    display: none;
}

.collapse-container .collapse.show {
    display: block;
}

.tertiary-nav-container,
.secondary-nav-container {
    height: 48px;
    background-color: #fafafa;
    transition-property: all;
    transition-duration: 0.5s;
    padding-left: 82px;
    position: relative;
}
.tertiary-nav-container {
    border-top: 0.75px solid #757575;
}
.secondary-nav-container:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    box-shadow: 0 1px 2px 0 #999999;
    height: 1px;
    top: -1px;
    left: 0;
}
.tertiary-nav-container .content,
.secondary-nav-container .content {
    float: left;
    clear: left;
    text-align: left;
}
.secondary-nav-container .nav-item,
.tertiary-nav-container .nav-item {
    line-height: 1;
}
.tertiary-nav-container .nav-item span,
.secondary-nav-container .nav-item span {
    font-family: AllProSans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: 32px;
    letter-spacing: normal;
    text-align: left;
    color: #dddddd;
    text-transform: none;
}
.tertiary-nav-container .nav-item a,
.secondary-nav-container .nav-item a {
    font-family: AllProSans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    line-height: 30px;
    color: #333333;
    text-transform: none !important;
    display: inline-block;
}

.tertiary-nav-container .nav-item.selected, .secondary-nav-container .nav-item.selected {
    border-bottom: 3px solid #00aacc;
}

.tertiary-nav-container .nav-item.selected a, .secondary-nav-container .nav-item.selected a {
    font-family: AllProSans, sans-serif;
    font-weight: 500;
    color: #00aacc;
}

.tertiary-nav-container .nav-item a:hover {
    font-family: AllProSans, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #00aacc;
}

.ternav-container-slide-down {
    top: 177px;
    transition-property: all;
    transition-duration: 0.5s;
    height: 48px;
    z-index: -1;
    padding-left: 82px;
}

.secondary-nav-container-slide-down {
    transition-property: all;
    transition-duration: 0.5s;
    height: 48px;
    z-index: -1;
    padding-left: 82px;
}

.secondary-nav-container {
    background-color: #fff;
}

.secondary-nav-container .league-dropdown .collapse-header .title {
    font-family: AllProSans, sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #333;
}

@media screen and (max-width: 1199px) {
    .right-container {
        display: none;
    }

    .mobile-right-container {
        display: flex;
        display: -moz-box;
    }

    .mobile-menu-container .main-subnav-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mobile-menu-container .main-nav-container .nav-item {
        font-family: AllProSans, sans-serif;
        font-size: 24px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: 0.3px;
        text-align: center;
        color: #eeeeee;
    }

    .mobile-menu-container .main-subnav-content .nav-item {
        font-family: AllProSans, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: 0.2px;
        text-align: center;
        color: #eeeeee;
    }

    .mobile-menu-container .nav-item a {
        color: #fff;
    }

    .mobile-menu-container .nav-item a:hover {
        color: #fff;
        text-decoration: underline;
    }

    .mobile-menu-container a {
        color: #fff;
    }

    .mobile-menu-container > .menu > .divider {
        width: 256px;
        height: 2px;
        opacity: 0.16;
        background-color: #ffffff;
        margin-top: 40px;
        margin-bottom: 24px;
    }
}

@media screen and (max-height: 568px) {
    .mobile-menu-container .main-nav-container .nav-item {
        font-size: 20px;
    }

    .mobile-menu-container .main-subnav-content .nav-item {
        font-size: 16px;
    }
}

.league-dropdown .collapse-container .data::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
}

.league-dropdown .collapse-container .data::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.league-dropdown {
    margin-top: 6px;
    position: absolute;
    right: 40px;
    width: 248px;
    z-index: 1;
}
.league-dropdown .collapse-container {
    background-color: #fafafa;
    border: solid 1px #7b7b7b;
    width: 100%;
}
.league-dropdown .collapse-container.collapse-open .collapse-header {
    border-bottom: 1px solid #7b7b7b;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.24);
}
.league-dropdown .collapse-header {
    margin: 0;
    position: relative;
    z-index: 1;
    border: none;
    display: flex;
    justify-content: space-between;
}

.league-dropdown .collapse-header .col-10 {
    max-width: 83.333333%;
}

.league-dropdown .collapse-header .title {
    margin: 0;
    height: 33px;
    line-height: 33px;
    padding-left: 16px;
}
.league-dropdown .collapse-container .toggle {
    margin: 5px 0 0;
    text-align: center;
    max-width: 16.666667%;
}
.league-dropdown .collapse-container .data {
    padding: 0;
    background-color: #ffffff;
    max-height: 240px;
    overflow: auto;
}
.league-dropdown .collapse-container .data .league-item {
    font-family: AllProSans, sans-serif;
    padding: 10px 16px;
    line-height: 20px;
}
.league-dropdown .collapse-container .data .league-item.selected {
    background-color: #fafafa;
    font-family: AllProSans, sans-serif;
    font-weight: 500;
}
.league-dropdown .collapse-container .data .league-item a {
    display: block;
}
.league-dropdown .collapse-container .data .league-item a.league-name {
    display: block;
    font-size: 14px;
    color: #757575;
}
.league-dropdown .collapse-container .data .league-item a.league-name strong {
    font-weight: inherit;
}

.league-dropdown .collapse-container .data .league-item a.team-name {
    display: block;
    font-weight: inherit;
    font-size: 16px;
    color: #333333;
}

.league-dropdown .collapse-container .data .league-item a.team-name em {
    font-style: normal;
}

.collapse-container {
    background-color: #fff;
    padding: 0;
    position: inherit;
}

.collapse-container .toggle {
    margin: 20px 0 0 0;
    text-align: right;
    padding-right: 3px;
}

.collapse-container .data {
    padding: 25px;
}

.collapse-container.content {
    margin: 25px 40px 30px 40px;
}
.collapse-header-button {
    appearance: none;
    border: none;
    padding: 0;
    width: 100%;
}

.collapse-header {
    border-bottom: 1px solid #ccc;
    margin: 0 15px 0;
}

.collapse-header .title {
    font-family: AllProSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.16;
    color: #333;
    margin: 16px 0 16px 0;
    padding: 0;
    text-align: left;
    display: flex;
    cursor: pointer;
}

.fantasy-plus-logo {
    background: #011a36;
    border-radius: 8px;
    display: inline-block;
    line-height: 1;
    padding: 8px 16px;
}

.fantasy-plus-logo-subscribed {
    background: #00db6e;
    border-radius: 8px;
    display: inline-block;
    line-height: 1;
    padding: 8px 16px;
}

.fantasy-plus-ribbon {
    background-color: #011a36;
    padding: 24px;
}

.fantasy-plus-ribbon-container {
    background-color: rgb(222, 220, 213);
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
}

@media screen and (max-width: 575px) {
    .collapse-container .data {
        padding: 16px 0;
    }

    .collapse-header {
        margin: 0 16px 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .secondary-nav-container .league-dropdown .collapse-header .title,
    .tertiary-nav-container .nav-item span,
    .secondary-nav-container .nav-item span,
    .tertiary-nav-container .nav-item a,
    .secondary-nav-container .nav-item a,
    .tertiary-nav-container .nav-item a:hover,
    .secondary-nav-container .nav-item a:hover {
        font-size: 12px;
    }
    .secondary-nav-container .league-dropdown {
        width: 208px;
        right: 16px;
    }
    .fantasy-tools .fantasy-nav-player-search-box {
        right: 235px;
    }
    .tertiary-nav-container,
    .secondary-nav-container {
        padding-left: 32px;
    }
    .secondary-nav-container .nav-item,
    .tertiary-nav-container .nav-item {
        padding-left: 6px;
        padding-right: 6px;
    }
}

@media screen and (max-width: 767px) {
    .center-container,
    .tertiary-nav-container,
    .main-subnav-container,
    .secondary-nav-container {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .collapse-header {
        margin: 0 40px 0;
    }
}
